import _express from "./lib/express";
export { _express as default };
export const application = _express.application,
      request = _express.request,
      response = _express.response,
      Route = _express.Route,
      Router = _express.Router,
      json = _express.json,
      query = _express.query,
      raw = _express.raw,
      text = _express.text,
      urlencoded = _express.urlencoded;
const _static = _express.static;
export { _static as static };